<template>
  <section>
    <div class="title" v-if="perfil && perfil.company_data">
      <small>Sua conta</small>
      <p>{{ perfil.company_data.name }}</p>
    </div>
    <div class="panel">
      <div v-for="box in panel" :key="box.topic">
        <router-link class="box" :to="box.url" v-if="box.role" :class="data == box.topic ? 'active' : ''" active-class="">
          {{ box.topic }}
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["data"],
  filters: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    ...mapState({
      perfil: state => state.user.dadosCompletos,
    }),
    managerIntegration() {
      return this.$store.state.user.gerenciarIntegracao;
    },
    managerUser() {
      return this.$store.state.user.gerenciarUsuario;
    },
    panel() {
      let items = [
        {
          topic: "Minha conta",
          url: "/settings",
          role: true,
        },
        {
          topic: "Integrações",
          url: "/settings/integrations",
          role: this.managerIntegration == 1,
        },
        {
          topic: "Plano",
          url: "/settings/plan",
          role: this.managerIntegration == 1,
        },
        {
          topic: "Usuários",
          url: "/settings/users",
          role: this.managerUser == 1,
        },
        {
          topic: "Contas vinculadas",
          url: "/settings/accounts-merged",
          role: this.managerIntegration == 1,
        },
        {
          topic: "Importações",
          url: "/settings/imports",
          role: this.managerIntegration == 1,
        },
      ];
      return items;
    },
  },
};
</script>

<style lang="scss" scoped>
.router-link-active {
  background: red;
  background-color: #747474;
  color: #e6e6e6;
}
.title {
  margin-bottom: 30px;
  small {
    font-size: 12px;
    text-align: left;
    color: #9b9b9b;
    margin-bottom: 5px;
    display: inline-block;
  }
  p {
    font-size: 18px;
    text-align: left;
    color: $dark;
  }
}
.panel {
  display: inline-block;
  align-items: center;
  margin-right: 45px;
  min-width: 325px;
  max-width: 325px;
  height: auto;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  div {
    &:last-child {
      .box {
        border-bottom: 0;
      }
    }
  }
  .box {
    border-bottom: 1px solid #ececec;
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 84px;
    background-color: #fff;
    margin: 0;
    font-family: $font__main;
    font-weight: bold;
    color: #747474;
    padding-left: 28px;
    padding-right: 28px;
    transition: all 350ms ease-in-out;
    width: 100%;
    white-space: nowrap;
    font-size: 18px;
    text-decoration: none;
    &:hover,
    &.active {
      text-decoration: none;
      background-color: #747474;
      color: #e6e6e6;

      &::after {
        background-color: #e6e6e6;
      }
    }

    &::after {
      height: 21px;
      width: 21px;
      content: "";
      display: block;
      background-color: #747474;
      -webkit-mask-image: url("../../../public/img/icons/icon.svg");
      mask-image: url("../../../public/img/icons/icon.svg");
      -webkit-mask-size: contain;
      -webkit-mask-repeat: no-repeat;
      position: relative;
      margin-left: 15px;
    }
  }
}

@media only screen and (max-width: 997px) {
  .panel {
    display: none;
  }
}
</style>
